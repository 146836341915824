import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './index.css';
import Home04 from './components/pages/Home04';
import NotFound from './components/pages/NotFound';


const App = () => (
    <Router>
      <Switch>
        <Route exact path="/" component={Home04} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
  
  render(<App />, document.getElementById('root'));
