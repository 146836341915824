import React, { Component } from 'react';
class Banner extends Component {
    render() {
        return (
            
                <div className="container-not-found">
                    <div className="center-div">
                        <div>
                            <img src="images/notFound.png" height="80%" width="60%" alt="notFound" className="image-not-found"/>
                        </div>
                        <div>
                            <a href="/"><button type="button" class="btn btn-primary btn-go-home">Go Home</button></a>
                        </div>     
                    </div>                                     
                </div>
        );
    }
}

export default Banner;
