import React, { Component } from 'react';
class Banner extends Component {
    render() {
        return (
            <section className="banner-section s4 two-home" id="home">
                <div className="container">
                    <div className="content-text position-relative">
                        <div className="animate-element wow delay5 fadeInDown" data-wow-delay="0.3s">
                            <div className="tag color-d1 lt-sp53 mg-b34">Software Engineer</div>
                            <h1 className="cd-headline clip is-full-width title color-d11 mg-b41">
                                <span>Hello, </span>
                                <span className="cd-words-wrapper color-d6">
                                <b className="is-visible"> I am David</b>
                                <b> I am David</b>
                                </span>
                            </h1>
                            <p className="color-d13 lt-sp02 mg-b51">
                                Hi, I am David Losilla, professional software engineer <br /> with {new Date().getFullYear() - 2017} years of experience.
                            </p>
                        </div>
                        <div className="animate-element wow delay5 fadeInUp" data-wow-delay="0.3s">
                            <div className="fl-btn btn-general color-s2 btn-hv-border">
                                <a href="documents/CV DAVID LOSILLA.pdf" download="Resume David Losilla.pdf" className="f-w500 color-d6 border-corner5 lt-sp01 text-two">Download Resume</a>
                            </div>
                        </div>
                    </div>
                    <div className="featured-post border-corner-bl">
                        <img src="images/section/47.jpg" alt="images" />
                        <div className="site-list has-border text-center animate-element wow delay5 fadeInUp" data-wow-delay="0.3s">
                            <a href="mailto:dlosilla.work@gmail.com" className="bg-s1"><i className="fa fa-envelope" aria-hidden="true" /></a>
                            <a href="https://www.linkedin.com/in/david-losilla" className="bg-s2"><i className="fa fa-linkedin" aria-hidden="true" /></a>
                            <a href="https://github.com/DavidLosilla" className="bg-s1"><i className="fa fa-github" aria-hidden="true" /></a>    
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Banner;
