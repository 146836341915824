import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class Skills extends Component {
    constructor(props) {
        super(props);
        this.state= {
            soft : [
                {
                    id: 1,
                    classimg: 'featured-post mg-b42',
                    srcimg: './images/section/75.png',
                    altimg: 'images',
                    title: 'TeamWork'
                    
                },
                {
                    id: 2,
                    classimg: 'featured-post mg-b43',
                    srcimg: './images/section/76.png',
                    altimg: 'images',
                    title: 'Communication '
                },
                {
                    id: 3,
                    classimg: 'featured-post mg-b42',
                    srcimg: './images/section/77.png',
                    altimg: 'images',
                    title: 'Negotiation'
                    
                },
                {
                    id: 4,
                    classimg: 'featured-post mg-b42',
                    srcimg: './images/section/78.png',
                    altimg: 'images',
                    title: 'Mentoring'
                    
                }
            ],
            card : [
                {
                    id: 1,
                    classimg: 'featured-post mg-b43',
                    srcimg: './images/section/20.png',
                    altimg: 'images',
                    title: 'Java'
                },
                {
                    id: 2,
                    classimg: 'featured-post mg-b42',
                    srcimg: './images/section/21.png',
                    altimg: 'images',
                    title: 'Spring Boot'
                    
                },
                {
                    id: 3,
                    classimg: 'featured-post mg-b42',
                    srcimg: './images/section/65.png',
                    altimg: 'images',
                    title: 'Architecture'
                    
                },
                {
                    id: 4,
                    classimg: 'featured-post mg-b43',
                    srcimg: './images/section/57.png',
                    altimg: 'images',
                    title: 'SQL'                    
                },
                {
                    id: 5,
                    classimg: 'featured-post mg-b42',
                    srcimg: './images/section/56.png',
                    altimg: 'images',
                    title: 'MongoDB'
                    
                },{
                    id: 6,
                    classimg: 'featured-post mg-b42',
                    srcimg: './images/section/66.png',
                    altimg: 'images',
                    title: 'REST API'
                    
                },{
                    id: 7,
                    classimg: 'featured-post mg-b42',
                    srcimg: './images/section/22.png',
                    altimg: 'images',
                    title: 'Microservices'
                    
                },{
                    id: 8,
                    classimg: 'featured-post mg-b42',
                    srcimg: './images/section/59.png',
                    altimg: 'images',
                    title: 'Docker'
                    
                },{
                    id: 9,
                    classimg: 'featured-post mg-b42',
                    srcimg: './images/section/60.png',
                    altimg: 'images',
                    title: 'Redis'
                    
                },
                {
                    id: 10,
                    classimg: 'featured-post mg-b42',
                    srcimg: './images/section/69.png',
                    altimg: 'images',
                    title: 'Kafka'
                    
                },{
                    id: 11,
                    classimg: 'featured-post mg-b42',
                    srcimg: './images/section/62.png',
                    altimg: 'images',
                    title: 'Cucumber'
                    
                },
                {
                    id: 13,
                    classimg: 'featured-post mg-b42',
                    srcimg: './images/section/64.png',
                    altimg: 'images',
                    title: 'JUnit'
                    
                },
                {
                    id: 14,
                    classimg: 'featured-post mg-b42',
                    srcimg: './images/section/70.png',
                    altimg: 'images',
                    title: 'DevOps'
                    
                },
                {
                    id: 15,
                    classimg: 'featured-post mg-b42',
                    srcimg: './images/section/72.png',
                    altimg: 'images',
                    title: 'HTML'
                    
                },
                {
                    id: 16,
                    classimg: 'featured-post mg-b42',
                    srcimg: './images/section/73.png',
                    altimg: 'images',
                    title: 'CSS'
                    
                },
                {
                    id: 17,
                    classimg: 'featured-post mg-b42',
                    srcimg: './images/section/71.png',
                    altimg: 'images',
                    title: 'JavaScript'
                    
                }
            ]
        }
    }    
    render() {
        return (              
            <section className="experienced s3" id="skills">
                <div className="container">
                    <div className="background-inner bg-s2">
                        <div className="flat-title animate-element wow delay5 fadeInDown" data-wow-delay="0.5s">
                        <h2 className="title-section color-d11 mg-b44 text-center">SOFT SKILLS</h2>
                        </div>
                        <div className="row animate-element wow delay5 fadeInUp" data-wow-delay="0.5s">
                            {
                                this.state.soft.map(data => (
                                    <div className="col-lg-3 center margin-top-20" key={data.id}>
                                        <div className="iconbox-s2">
                                            <div className={data.classimg}><img src={data.srcimg} alt={data.altimg} /></div>
                                            <h3 className="title mg-b21"><Link to="#" className="color-d11">{data.title}</Link></h3>
                                        </div>
                                    </div>  
                                ))
                            }                       
                        </div>
                    </div>
                </div>
                <br></br>
                <div className="container">
                    <div className="background-inner bg-s2">
                        <div className="flat-title animate-element wow delay5 fadeInDown" data-wow-delay="0.5s">
                        <h2 className="title-section color-d11 mg-b44 text-center">HARD SKILLS</h2>
                        </div>
                        <div className="row animate-element wow delay5 fadeInUp" data-wow-delay="0.5s">
                            {
                                this.state.card.map(data => (
                                    <div className="col-lg-3 center margin-top-20" key={data.id}>
                                        <div className="iconbox-s2">
                                            <div className={data.classimg}><img src={data.srcimg} alt={data.altimg} /></div>
                                            <h3 className="title mg-b21"><Link to="#" className="color-d11">{data.title}</Link></h3>
                                        </div>
                                    </div>  
                                ))
                            }                       
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Skills;
