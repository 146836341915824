import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class TopEducation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contenttop : [
                {
                    id: 1,
                    classname: 'experience-box t3',
                    datawow: '0.3s',
                    year: '2015 - 2019',
                    title: 'Complutense University of Madrid',
                    text: 'Computer Engineering Degree'
                }
            ],
            contentcenter : [
                {
                    id: 1,
                    classname: 'experience-box t3',
                    datawow: '0.3s',
                    year: 'OpenWebinars',
                    title: 'Docker for developers',
                    text: 'https://openwebinars.net/cert/7r5E5'
                },
                {
                    id: 2,
                    classname: 'experience-box t3',
                    datawow: '0.5s',
                    year: 'OpenWebinars',
                    title: 'Hexagonal architecture',
                    text: 'https://openwebinars.net/cert/enR9l'
                },
                {
                    id: 3,
                    classname: 'experience-box t3',
                    datawow: '0.7s',
                    year: 'OpenWebinars',
                    title: 'Native development in kubernetes',
                    text: 'https://openwebinars.net/cert/NDJQ9'
                },
                {
                    id: 4,
                    classname: 'experience-box t3',
                    datawow: '0.3s',
                    year: 'OpenWebinars',
                    title: 'Kubernetes on AWS',
                    text: 'https://openwebinars.net/cert/qBQrE'
                },
                {
                    id: 5,
                    classname: 'experience-box t3',
                    datawow: '0.5s',
                    year: 'OpenWebinars',
                    title: 'Kubernetes for developers',
                    text: 'https://openwebinars.net/cert/MaGbr'
                }
            ],
            contentbot : [
                {
                    id: 1,
                    classname: 'experience-box t3',
                    datawow: '0.8s',
                    year: '2022 - Present',
                    title: 'Technical Lead at',
                    office: 'Alten',
                    text: 'Integrated in Inditex, Retail Management backend team leader, in charge of team management, analysis and architecture development. Development mainly in Java, Spring Boot, MongoDB, Open API, Kafka and tools such as Grafana.'
                },
                {
                    id: 2,
                    classname: 'experience-box t3',
                    datawow: '1s',
                    year: '2021 - 2022',
                    title: 'Software Engineer at',
                    office: 'Paradigma Digital',
                    text: 'Located in BBVA, integrated in the Extended Security Services development team, in charge of generating new security services based on biometrics. Development in Java, Spring Boot, MongoDB, Kafka, Redis and tools such as Jenkins or Consul.'
                },
                {
                    id: 3,
                    classname: 'experience-box t3',
                    datawow: '1.2s',
                    year: '2020 - 2021',
                    title: 'Software Engineer at',
                    office: 'Sopra Steria',
                    text: 'Integrated in ING, located in the CEL development team, in charge of generating new services for the adaptation and enrichment of banking transactions. Development mainly in Java, Spring Boot, JPA, Kafka and tools such as Jenkins, Kibana or Grafana.'
                },
                {
                    id: 4,
                    classname: 'experience-box t3',
                    datawow: '0.8s',
                    year: '2019 - 2020',
                    title: 'Software Engineer at',
                    office: 'Accelya',
                    text: 'Placed in the Payment Solutions development team, in charge of generating new solutions related to banking movements for the main airlines in the sector. Development mainly in Java, together with Spring Boot, C#, .Net and Infomix.'
                },
                {
                    id: 5,
                    classname: 'experience-box t3',
                    datawow: '1s',
                    year: '2018 - 2019',
                    title: 'Software Engineer at',
                    office: 'Kiuwan',
                    text: 'Integrated in the development team, in charge of the creation of new product functionalities, such as the development of REST services or reporting. Development mainly in Java, together with Spring, Hibernate, JPA and ZK.'
                }
            ]
        }
    }
    
    render() {
        return (
            <div className="animate-element delay5 fadeInUp">
                <div className="padding-bottom">
                    <div className="row">
                        {
                            this.state.contenttop.map(data => (
                                <div className="col-lg-4 col-12 last-col animate-element wow delay5 fadeIn" data-wow-delay={data.datawow} key={data.id}>
                                    <div className={data.classname}>
                                        <div className="box-inner">
                                        <h3 className="f-info bg-s2 color-d6">{data.year}</h3>
                                        <div className="exp-wrap">
                                            <div className="s-info color-d11 mg-b11">{data.title}</div>
                                            <p className="color-d13">
                                            {data.text}
                                            </p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="padding-bottom">
                    <div className="row">
                        {
                            this.state.contentcenter.map(data => (
                                <div className="col-lg-4 col-12 last-col animate-element wow delay5 fadeIn" data-wow-delay={data.datawow} key={data.id}>
                                    <div className={data.classname}>
                                        <div className="box-inner">
                                        <h3 className="f-info bg-s2 color-d6">{data.year}</h3>
                                        <div className="exp-wrap">
                                            <a href={data.text}><div className="s-info color-d11 mg-b11">{data.title}</div></a>                                            
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="row">
                    {
                        this.state.contentbot.map(data => (
                            <div className="col-lg-4 col-12 last-col animate-element wow delay5 fadeIn" data-wow-delay={data.datawow} key={data.id}>
                                <div className={data.classname}>
                                    <div className="box-inner">
                                    <h3 className="f-info bg-s2 color-d6">{data.year}</h3>
                                    <div className="exp-wrap">
                                        <div className="s-info color-d11 mg-b11">{data.title} <Link to="#">{data.office}</Link></div>
                                        <p className="color-d13">
                                            {data.text} 
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default TopEducation;
