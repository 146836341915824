const menus = [
    {
        id: 1,
        tomenu: "#home",
        namemenu: "Home"
    },
    {
        id: 2,
        tomenu: "#about",
        namemenu: "About"
    },
    {
        id: 3,
        tomenu: "#services",
        namemenu: "Services"
    },
    {
        id: 4,
        tomenu: "#resume",
        namemenu: "Resume"
    },
    {
        id: 5,
        tomenu: "#skills",
        namemenu: "Skills"
    },
    {
        id: 6,
        tomenu: "#footer",
        namemenu: "Contact"
    }
];



export default menus ;