import React, { Component } from 'react';
import {Header, Banner} from '../layouts/notFound/index';

class NotFound extends Component {
    render() {
        return (
            <Banner />
        );
    }
}

export default NotFound;