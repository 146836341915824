import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class Footer extends Component {
    constructor(props){
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="tf-modal-content justify-content-end"> 
                <footer id="footer" className="footer footer-s4">                    
                    <div id="bottom" className="bottom-s3">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4">                                    
                                </div>
                                <div className="col-lg-6">
                                    <ul className="content-menu widget-nav-menu color-s2">
                                        <li key="77"><Link to="#home" className="click-model">Home</Link></li>
                                        <li key="78"><Link to="#about" className="click-model">About</Link></li>
                                        <li key="79"><Link to="#services" className="click-model">Services</Link></li>
                                        <li key="70"><Link to="#resume" className="click-model">Resume</Link></li>
                                        <li key="71"><Link to="#skills" className="click-model">Skills</Link></li>
                                    </ul>
                                </div>
                                <div className="col-lg-2">
                                    <div className="socials-list color-s2">
                                        <a href="mailto:dlosilla.work@gmail.com" className="bg-s1"><i className="fa fa-envelope" aria-hidden="true" /></a>
                                        <a href="https://www.linkedin.com/in/david-losilla" className="bg-s2"><i className="fa fa-linkedin" aria-hidden="true" /></a>
                                        <a href="https://github.com/DavidLosilla" className="bg-s1"><i className="fa fa-github-square" aria-hidden="true" /></a>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;
